<template>
    <!--<v-card max-width="600" class="pa-4 grey darken-4">-->
    <div class="flex-grow-1">
        <v-scale-transition>
            <!--<v-container class="pa-4 grey darken-4" v-show="show">-->
            <v-row v-show="show" class="justify-center ma-0">
                <!--<v-col cols="12" md="8" sm="10" lg="6" class="pa-0">-->
                <v-col cols="12" lg="8" md="10" sm="12" class="pa-0">
                <!--<v-col cols="12" class="pa-0">-->
                    <v-toolbar dense dark color="blue-grey darken-4" elevation="0">
                        <v-toolbar-title>Акция</v-toolbar-title>
                    </v-toolbar>
                    <v-sheet elevation="12" class="pa-4 dark blue-grey darken-3">
                        <v-form
                                ref="form"
                                v-model="valid"
                                lazy-validation
                        >
                            <v-row class="mb-n3">
                                <v-col cols="12" sm="4" class="mb-n4">
                                    <v-text-field
                                            v-model="discount"
                                            :rules="[v => !!v || 'Обязательное поле', discountValidate]"
                                            label="Скидка"
                                            required
                                            outlined
                                            hint="% < 100, либо номинал"
                                            persistent-hint
                                            ref="discountField"
                                    ></v-text-field>
                                </v-col>
                                <v-col cols="12" sm="4" class="mb-n4">
                                    <v-select
                                            v-model="type"
                                            :items="typeItems"
                                            :rules="[v => !!v || 'Обязательное поле']"
                                            label="Тип"
                                            required
                                            hint="Единица измерения"
                                            persistent-hint
                                            outlined
                                            return-object
                                    ></v-select>
                                </v-col>
                                <v-col cols="12" sm="4" class="mb-3">
                                    <v-select
                                            v-model="status"
                                            :items="statusItems"
                                            :rules="[v => !!v || 'Обязательное поле']"
                                            label="Статус"
                                            required
                                            hide-details
                                            outlined
                                            return-object
                                    ></v-select>
                                </v-col>
                            </v-row>

                            <v-row class="mb-n7">
                                <v-col cols="12" sm="3" class="mb-n4">
                                    <v-text-field
                                            v-model="price"
                                            :rules="[v => !!v || 'Обязательное поле', priceValidate]"
                                            label="Стоимость"
                                            required
                                            outlined
                                            :hint="'Стоимость в копейках'"
                                            persistent-hint
                                    ></v-text-field>
                                </v-col>

                                <v-col cols="12" sm="3" class="mb-n4">
                                    <v-text-field
                                            v-model="lowerLimit"
                                            :rules="[lowerLimitValidate]"
                                            label="Порог"
                                            outlined
                                            persistent-hint
                                            hint="Нижний лимит"
                                    ></v-text-field>
                                </v-col>

                                <v-col cols="12" sm="6">
                                    <v-text-field
                                            v-model="priceString"
                                            label="Отображаемая цена"
                                            required
                                            outlined
                                            hint="Отображаемая цена"
                                            persistent-hint
                                    ></v-text-field>
                                </v-col>
                            </v-row>

                            <!--:counter="10"-->
                            <v-row class="mb-n7">
                                <v-col cols="12">
                                    <v-textarea
                                            rows="1"
                                            auto-grow
                                            v-model="name"
                                            :rules="[v => !!v || 'Обязательное поле']"
                                            label="Название скидки"
                                            required
                                            outlined
                                            :hint="name + ' ' + discount + (type.value == 'nominal' ? ' рублей' : type.text) + ( lowerLimit > 0? ' при покупке от ' + lowerLimit + ' рублей' : '')"
                                            persistent-hint
                                    ></v-textarea>
                                </v-col>
                            </v-row>

                            <v-row class="mb-n6">
                                <v-col cols="12" class="mb-n1">
                                    <v-textarea
                                            rows="1"
                                            auto-grow
                                            v-model="description"
                                            label="Описание скидки"
                                            outlined
                                            hint="Описание в сертификате"
                                            persistent-hint
                                    ></v-textarea>
                                </v-col>
                            </v-row>

                            <v-row class="mb-n7">
                                <v-col cols="12" sm="6" class="mb-n4">
                                    <v-select
                                            v-model="notifyApply"
                                            :items="notifyApplyItems"
                                            :rules="[v => !!v || 'Обязательное поле']"
                                            label="Уведомление"
                                            required
                                            outlined
                                            hint="Уведомлять об операциях с сертификатом"
                                            persistent-hint
                                            readonly
                                            return-object
                                    ></v-select>
                                </v-col>
                                <v-col cols="12" sm="6">
                                    <v-select
                                            v-model="expireType"
                                            :items="expireTypeItems"
                                            :rules="[v => !!v || 'Обязательное поле']"
                                            label="Истекает"
                                            required
                                            hint="Срок активации"
                                            persistent-hint
                                            outlined
                                            return-object
                                    ></v-select>
                                </v-col>
                            </v-row>

                            <v-row class="mb-n7">
                                <v-col cols="12" sm="4" class="mb-n4">
                                    <v-select
                                            v-model="applyType"
                                            :items="applyTypeItems"
                                            :rules="[v => !!v || 'Обязательное поле']"
                                            label="Активировать"
                                            required
                                            outlined
                                            hint="Активация сертификата"
                                            persistent-hint
                                            return-object
                                    ></v-select>
                                </v-col>
                                <v-col cols="12" sm="4" class="mb-n4">
                                    <v-select
                                            v-model="genType"
                                            :items="genTypeItems"
                                            :rules="[v => !!v || 'Обязательное поле']"
                                            label="Генерация кода"
                                            required
                                            hint="Генерации кода сертификата"
                                            persistent-hint
                                            outlined
                                            return-object
                                    ></v-select>
                                </v-col>
                                <v-col cols="12" sm="4" >
                                    <v-text-field
                                            v-model="genValue"
                                            :rules="[genValueValidate]"
                                            label="Код"
                                            outlined
                                            hint="Код сертификата"
                                            persistent-hint
                                            :readonly="genValueReadOnly"
                                    ></v-text-field>
                                </v-col>
                            </v-row>

                            <v-row class="mb-n7">
                                <v-col cols="12" sm="6" class="mb-n4">
                                    <v-menu
                                            v-model="menu"
                                            :close-on-content-click="false"
                                            :nudge-right="40"
                                            transition="scale-transition"
                                            offset-y
                                            min-width="auto"
                                    >
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-text-field
                                                    v-model="buyBeginTime"
                                                    label="Время начала"
                                                    hint="Время начала продажи(активации) в каталоге"
                                                    persistent-hint
                                                    readonly
                                                    outlined
                                                    v-bind="attrs"
                                                    v-on="on"
                                                    clearable
                                                    @click:clear="filterClearDate('buyBeginTime',$event)"
                                            ></v-text-field>
                                        </template>
                                        <v-date-picker
                                                v-model="buyBeginTime"
                                                @input="menu = false"
                                                :show-adjacent-months="true"
                                                :show-current="new Date().toISOString().substr(0, 10)"
                                                :min="new Date().toJSON().slice(0,10)"
                                                header-color="pink darken-4"
                                                first-day-of-week="1"
                                        ></v-date-picker>
                                    </v-menu>
                                </v-col>
                                <v-col cols="12" sm="6">
                                    <v-menu
                                            v-model="menu2"
                                            :close-on-content-click="false"
                                            :nudge-right="40"
                                            transition="scale-transition"
                                            offset-y
                                            min-width="auto"
                                    >
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-text-field
                                                    v-model="buyEndTime"
                                                    label="Время окончания"
                                                    hint="Время окончания продажи(активации) в каталоге"
                                                    persistent-hint
                                                    readonly
                                                    outlined
                                                    v-bind="attrs"
                                                    v-on="on"
                                                    clearable
                                                    @click:clear="filterClearDate('buyEndTime',$event)"
                                            ></v-text-field>
                                        </template>
                                        <v-date-picker
                                                v-model="buyEndTime"
                                                @input="menu2 = false"
                                                :show-adjacent-months="true"
                                                :show-current="new Date().toISOString().substr(0, 10)"
                                                :min="new Date().toJSON().slice(0,10)"
                                                header-color="pink darken-4"
                                                first-day-of-week="1"
                                        ></v-date-picker>
                                    </v-menu>
                                </v-col>
                            </v-row>

                            <v-row class="">
                                <v-col cols="12" sm="6" class="mb-3">
                                    <v-text-field
                                            v-model="createTime"
                                            label="Создан в UTC"
                                            outlined
                                            readonly
                                            hide-details
                                    ></v-text-field>
                                </v-col>
                                <v-col cols="12" sm="6" class="mb-4">
                                    <v-text-field
                                            v-model="updateTime"
                                            label="Изменен в UTC"
                                            outlined
                                            readonly
                                            hide-details
                                    ></v-text-field>
                                </v-col>
                            </v-row>

                        </v-form>
                        <v-card-actions class="pa-0">
                            <v-btn color="primary" @click="copy" :disabled="!id">{{'Копировать'}}</v-btn>
                            <v-spacer></v-spacer>
                            <v-btn :color="(id ? 'success' : 'warning')" @click="submit" :disabled="(!id && !holderId) || !valid">{{!id ? 'Добавить' : 'Изменить'}}</v-btn>
                            <!--<v-btn color="primary" :disabled="!valid" type="submit">Login</v-btn>-->
                        </v-card-actions>
                        <!--</v-card>-->
                    </v-sheet>
                </v-col>
            </v-row>
            <!--</v-container>-->
        </v-scale-transition>
    </div>
</template>

<script>
  export default {
    name: "DiscountForm",
    props: ['param', 'holder', 'mode'],

    computed:{
      genValueReadOnly: function(){
        return !(this.genType.value == 'fixed');
      }
    },

    watch:{
      param:
        function(v){
          //console.log(v);
          this.init();
        },

      mode:
        function(v){
          //console.log(v);
          this.init();
        },

      price:
        function(value) {
          if (!this.id) {
            this.priceString = (isNaN(parseInt(value,10)) ? 0 : parseInt(value,10)/100) + ' бонусных рублей';
          }
        },
      applyType:
        function(v) {
          if (!this.id) {
            if (v.value == 'manual'){
              this.genType = this.genTypeItems.filter( v => (v.value == 'auto'))[0];
            }
            else{
              this.genValue = '';
            }
          }
        },
      genType: function(v){
        if (!this.id) {
            if (v.value == 'auto'){
              this.genValue = '';
            }
        }
      },
    },

    //id,holderId
    data: () => ({
      valid: false,
      show: false,
      menu: null,
      menu2: null,

      id: null,
      holderId: null,
      discount: 0,
      price: 0,
      priceString: '',
      name: '',
      description: '',
      status: {},
      statusItems: [
        {text: 'Активен', value: 'active'},
        {text: 'Отключен', value: 'disabled'},
      ],
      type: {},
      typeItems: [
        {text: '%', value: 'discount'},
        {text: 'руб', value: 'nominal'},
      ],
      lowerLimit: null,
      notifyApply: {},
      notifyApplyItems: [{ text: 'Включено', value: 'active'}],
      genType: {},
      genTypeItems: [
        {text: 'Авто', value: 'auto'},
        {text: 'Задано', value: 'fixed'},
      ],
      discountSetId: null,
      createTime: '',
      updateTime: '',
      buyBeginTime: null,
      buyEndTime: null,
      expireType: {},
      expireTypeItems: [
        {text: 'Автоматически', value:'auto'},
        {text: 'Через 2 дня', value:'2 day'},
        {text: 'Через 3 дня', value:'3 day'},
      ],
      genValue: '',
      applyType: {},
      applyTypeItems: [
        {text: 'Авто', value:'auto'},
        {text: 'Требуется', value:'manual'},
      ],
    }),

    mounted(){
      //console.log(new Date().toJSON().slice(0,10));
      //console.log(this.$route);
      this.init();
    },

    methods: {
      filterClearDate(sender){
        if (sender == 'buyBeginTime'){
          this.buyBeginTime = null;
        }
        else if (sender == 'buyEndTime'){
          this.buyEndTime = null;
        }
        //console.log($event);
      },

      init(){
        this.show = true;
        // console.log('init');
        //console.log(this.mode);
        // console.log(this.holder);
        //console.log(this.refValidate('holder::multinet::100046'));
        if (!this.param && !!this.holder){//логически обработать holderId(holder)
          this.id = null;
          this.holderId = this.holder;
          this.name = '';
          this.description = '';

          this.discount = 0;
          this.price = 0;
          //this.priceString = '';
          this.name = '';
          this.description = '';
          this.type = this.typeItems[0];
          this.status = this.statusItems[0];
          this.lowerLimit = null;
          this.notifyApply = this.notifyApplyItems[0];
          //this.discountSetId = null;
          this.createTime = '';
          this.updateTime ='';
          this.buyBeginTime = null;
          this.buyEndTime = null;
          this.expireType = this.expireTypeItems[0];
          this.genType = this.genTypeItems[0];
          this.genValue = '';
          this.applyType = this.applyTypeItems[0];
          //this.resetValidation();
          this.$nextTick(() => this.resetValidation());
          //this.$emit('update-title', 'Пользователь: Новый');
          //this.$emit('update-form', {title: '<Новый>'});
        }
        else if (!!this.param){
          this.id = this.param;
          //потом сделать наоборот, если цифра то гет данные иначе новый
          this.get(this.id);
        }
        else{
          this.$store.dispatch("showMessage", {text: 'Ошибка инициализации скидки', error: true});
        }
        //console.log(this.holder);
      },

      discountValidate(v){
        //console.log(v);
        return (this.type.value == 'discount'
                    ? /^(\d|\.)+$/.test(v) && v > 0 && v <= 100
                    : /^(\d|\.)+$/.test(v) )
                || '% < 100, либо номинал';
      },

      typeValidate(v){
        this.$refs.discountField.validate();
        return true;
      },

      priceValidate(v){
        //console.log(value);
        return  /^(\d|\.)+$/.test(v) || 'Стоимость в копейках';
      },

      lowerLimitValidate(v){
        //console.log(v);
        return ( (!!v) ? ( /^(\d|\.)+$/.test(v) || 'Нижний лимит' ) : true );
      },

      genValueValidate(v){
        //console.log(v);
        return ( (!!v) ? ( /^(\w|[А-Яа-я])+$/.test(v) || 'Код сертификата' ) : true );
      },

      get(id){
        this.$store.dispatch("setLoading", true);

        try{
          this.$store.dispatch("getHolderDiscount", id)
            .then((response) => {
                //console.log(response);
                if (response.data){

                  this.holderId = response.data.holderId;
                  this.name = response.data.name;
                  this.description = response.data.description;
                  this.discount = response.data.discount;
                  this.price = response.data.price;
                  this.priceString = response.data.priceString;
                  this.type = this.typeItems.filter( v => (v.value == response.data.type))[0];
                  this.status = this.statusItems.filter( v => (v.value == response.data.status))[0];
                  this.lowerLimit = response.data.lowerLimit;
                  this.notifyApply = this.notifyApplyItems.filter( v => (v.value == response.data.notifyApply))[0];
                  this.discountSetId = response.data.discountSetId;
                  this.createTime = response.data.createTime;
                  this.updateTime = response.data.updateTime;
                  this.buyBeginTime = response.data.buyBeginTime;
                  this.buyEndTime = response.data.buyEndTime;
                  this.expireType = this.expireTypeItems.filter( v => (v.value == response.data.expireType))[0];
                  this.genType = this.genTypeItems.filter( v => (v.value == response.data.genType))[0];
                  this.genValue = response.data.genValue;
                  this.applyType = this.applyTypeItems.filter( v => (v.value == response.data.applyType))[0];
                  //this.resetValidation();
                  this.$nextTick(() => this.resetValidation());
                  //this.$emit('update-form', {title: this.holderName, holderType: this.holderType.value, holderId: this.holderId});
                  //this.$store.dispatch("routerPushWrap", '/holder/' + response.data.holderId);
                }
                else{
                  //this.$emit('update-holder', {holderId: null});
                  // this.$store.dispatch("routerPushWrap", {path: '/holders'});
                  throw new Error("Ошибка получения данных");
                }
            })
            .then(null,(error) => {
                //console.log(error);
              this.$store.dispatch("showMessage", {text: 'Ошибка получения акции: ' + error.message, error: true});
                //this.$store.dispatch("routerPushWrap", {path: '/discounts'});
            })
            .finally(() => {
                this.$store.dispatch("setLoading", false);
                if (this.mode == 'copy') this.id = null;
            });
        }
        catch(e){
          this.$store.dispatch("showMessage", {text: e.message, error: true});
          this.$store.dispatch("setLoading", false);
          if (this.mode == 'copy') this.id = null;
        }
      },
      
      copy(){
        //this.id = null;
        this.$store.dispatch("routerPushWrap", {name: "HolderDiscountCopy", params:{ holderId: this.holderId, discountId: this.id}});
        //this.$store.dispatch("routerPushWrap", "/holder/" + this.holderId + "/discount/" + this.id + "/copy");
      },
      
      submit(){
        if (this.validate()){
            //console.log(a);
          this.$store.dispatch("setLoading", true);
            //const { sortBy, sortDesc, page, itemsPerPage } = this.options;
            const reqParams = {
              id: this.id,
              holderId: this.holderId,
              name: this.name,
              description: this.description,
              discount: this.discount,
              price: this.price,
              priceString: this.priceString,
              type: this.type.value,
              status: this.status.value,
              lowerLimit: this.lowerLimit,
              notifyApply: this.notifyApply.value,
              discountSetId: this.discountSetId,
              buyBeginTime: this.buyBeginTime,
              buyEndTime: this.buyEndTime,
              expireType: this.expireType.value,
              genType: this.genType.value,
              genValue: this.genValue,
              applyType: this.applyType.value
            };
          //console.log(reqParams);
          let reqMethod = '';
          if (!this.id){
            reqMethod = 'postHolderDiscount';//+ 'moduleName'
          }
          else{
            reqMethod = 'putHolderDiscount';
          }
          
          try{
              this.$store.dispatch(reqMethod, reqParams)
                .then((response) => {
                  //получить ид пользователя и перейти на пользователя
                  //console.log(response);
                  //{ name: 'user', params: { userId: 123 }}
                  if (reqMethod == 'postHolderDiscount'){
                    //this.$store.dispatch("routerPushWrap", {name: "Discount", params: {discountId: '' + response.data.discountId}});//'/holder/' + response.data.holderId);
                    //this.$store.dispatch("routerPushWrap", {path:'/discount/' + response.data.holderDiscountId});
                    //this.$store.dispatch("routerPushWrap", '/holder/'+ response.data.holderId + '/discount/');// + response.data.holderDiscountId);
                    //this.partners = response.data.data.map((i) => ({id: i.id, name: i.shortName, holderRouteId: i.holderRouteId}));
                    //this.$emit('update-discount', {holderDiscountId: response.data.holderDiscountId, holderId: response.data.holderId});
                    //this.id = response.data.holderDiscountId;
                    //this.get(this.id);
                    this.$store.dispatch("routerPushWrap", {name: "HolderDiscounts", params:{ holderId: this.holderId}});
                  }
                  else if (reqMethod == 'putHolderDiscount'){
                    this.get(response.data.holderDiscountId);
                  }
                })
                .then(null,(error) => {
                    console.log(error);
                    //this.$store.dispatch("showMessage", {text: 'Ошибка получения данных', error: true});
                })
                .finally(() => {
                  this.$store.dispatch("setLoading", false);
                });
          }
          catch(e){
            this.$store.dispatch("showMessage", {text: e.message, error: true});
            this.$store.dispatch("setLoading", false);
          }
        }
      },

      transliterate(word){
        if (!word) return "";
        // const a = {"Ё":"YO","Й":"I","Ц":"TS","У":"U","К":"K","Е":"E","Н":"N","Г":"G","Ш":"SH","Щ":"SCH","З":"Z","Х":"H","Ъ":"'","ё":"yo","й":"i","ц":"ts","у":"u","к":"k","е":"e","н":"n","г":"g","ш":"sh","щ":"sch","з":"z","х":"h","ъ":"'","Ф":"F","Ы":"I","В":"V","А":"a","П":"P","Р":"R","О":"O","Л":"L","Д":"D","Ж":"ZH","Э":"E","ф":"f","ы":"i","в":"v","а":"a","п":"p","р":"r","о":"o","л":"l","д":"d","ж":"zh","э":"e","Я":"Ya","Ч":"CH","С":"S","М":"M","И":"I","Т":"T","Ь":"'","Б":"B","Ю":"YU","я":"ya","ч":"ch","с":"s","м":"m","и":"i","т":"t","ь":"'","б":"b","ю":"yu"};
        const a = {"Ё":"YO","Й":"I","Ц":"TS","У":"U","К":"K","Е":"E","Н":"N","Г":"G","Ш":"SH","Щ":"SCH","З":"Z","Х":"H","Ъ":"","ё":"yo","й":"i","ц":"ts","у":"u","к":"k","е":"e","н":"n","г":"g","ш":"sh","щ":"sch","з":"z","х":"h","ъ":"","Ф":"F","Ы":"I","В":"V","А":"a","П":"P","Р":"R","О":"O","Л":"L","Д":"D","Ж":"ZH","Э":"E","ф":"f","ы":"i","в":"v","а":"a","п":"p","р":"r","о":"o","л":"l","д":"d","ж":"zh","э":"e","Я":"Ya","Ч":"CH","С":"S","М":"M","И":"I","Т":"T","Ь":"","Б":"B","Ю":"YU","я":"ya","ч":"ch","с":"s","м":"m","и":"i","т":"t","ь":"","б":"b","ю":"yu"," ":"", "\"":"", "'":""};

        return word.split('').map(function (char) {
          return (a[char] === undefined ? char : a[char]);
        }).join("");
      },

      validate(){
        //console.log();
        return this.$refs.form.validate();
      },

      reset(){
        this.$refs.form.reset();
      },

      resetValidation(){
        if (this.$refs['form']){
          this.$refs.form.resetValidation();
        }
      },
    },
  };
  
</script>

<style scoped>
</style>